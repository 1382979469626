import React, { useEffect, useState } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import Header from './Header'
import Dropdown from 'react-bootstrap/Dropdown';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Link, useLocation } from "react-router-dom";
import { UnStake ,UseRoundValue,getUser } from '../hooks/UseStake';
import { getAccount } from '../hooks/useAccount';
import { convertToDate } from '../hooks/UseStake';

const Withdraw = () => {
  let location = useLocation();
console.log("LOcation",location)
  const [showwithdraw, setShowWithdraw] = useState(true)
  const [withdraw, setWithdraw] = useState("0");
  const [accountInfo, setAccountInfo] = useState("");
  const [isMax,setIsMax] = useState(false);
  // const [Maxvalwithdraw, setmaxvalwithdraw] = useState("0");
  const [Maxwithdraw, setmaxwithdraw] = useState("0");

  const maxwithdraw = () => {
    const userStakedAmount = location?.state?.userStakedAmount;
    const data = userStakedAmount / 10 ** location?.state?.tokenInfo?.decimals
    console.log(data,"maxwithdraw")
    if (parseFloat(data) >= 0) {
      setmaxwithdraw(data);
    }
  }

  const Withdraw = async () => {
    const pid = location?.state?.pid;
    const decimal = location?.state?.tokenInfo?.decimals;
    const amt = isMax ? Maxwithdraw : withdraw;
    await UnStake(accountInfo, pid, amt, decimal, isMax).then((data,err) => {
    if(err){
      console.log("errrrrr", err)
    }else{
      console.log("UNSTAKE DATA",data)
    }
    })
  }

  useEffect(() => {
    setAccountInfo(getAccount());
    checkWithdraw()

    // setmaxvalwithdraw(location?.state?.userStakedAmount / 10 ** location?.state?.decimals);
    // getunstateDate()
   
  }, []);


 

  const checkWithdraw = async () => {
    const unstakeDate = await convertToDate(location?.state?.userstatedate);
    const newData = new Date()
    const checkDate = newData >= unstakeDate ?  setShowWithdraw(false) : setShowWithdraw(true);
    console.log("unstakeDate",unstakeDate,"---","newData","---",newData,"---","checkDate",checkDate)
 
}


return (
  <section  className='newtheme'>
  <div className='innerpages stakingpage'>
    <Header />
    <div className='container container_custom mt-5 pt-main-top pb-4'>
      <div className='headsec mb-3'>
        <div>
          <h3 className='headtitle'>Withdraw</h3>
        </div>
        <div>
          <Link to="/calculate" className='headright'>IRENA Calculator</Link>
        </div>
      </div>
      <div className='cardsec themecard'>

        <div className="step_count secondstep mb-4 active">
          <div className="card shadowstyle">
            <div className="card-body">
              <p className="card-title mb-3">Invest {location?.state?.tokenInfo?.symbol} and Reward Token</p>
              <div className='row'>
                <div className='col-md-6 mb-2'>
                  <div className="input-group">
                    <input type="text" className="form-control" placeholder="Investment Amount" aria-label="Investment Amount" aria-describedby="basic-addon2" value={ isMax ? Maxwithdraw : withdraw} 
                     onChange={(e)=> { setWithdraw(e.target.value); setIsMax(false);}} />
                    <div className="input-group-append">
                      <span className="input-group-text" id="basic-addon2"><button className='input_btn' onClick={() => { setIsMax(true); maxwithdraw() }}>Max</button></span>
                    </div>
                  </div>
                  <small className='descinput'>UnStake After {location?.state?.userstatedate}</small>
                </div>

                <p className='descline mt-2'>* In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying.</p>
              </div>

              {/* <div className='stakingbtnsec mt-4'>
                <button className="btn_yellow mb-3 mb-md-0" disabled={showwithdraw} 
                onClick={async()=> { await Withdraw()}}>Submit</button>
              </div> */}
              <div className='stakingbtnsec mt-4'>
                <button className="btn_yellow mb-3 mb-md-0"
                onClick={async()=> { await Withdraw()}}>Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </section>
)

}
export default Withdraw
