import logo from './logo.svg';
import './App.css';
import Home from '../src/component/home'
import Staking from './component/staking';
import Rewards   from './component/rewards';
import Lockedstaking   from './component/Lockedstaking';
import Calculate  from './component/Calculate';
import Withdraw from './component/Withdraw';
import {Route,Routes, BrowserRouter} from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';

function App() {
  
  return (
    <BrowserRouter >
     <div><Toaster /></div>
      <Routes>
        <Route path="/" element={ <Home />} />
        <Route path="/staking" element={ <Staking />} />
        <Route path="/rewards" element={ <Rewards />} />
        <Route path="/lockedstaking" element={ <Lockedstaking />} />
        <Route path="/calculate" element={ <Calculate />} />
        <Route path="/withdraw" element={ <Withdraw />} />        
      </Routes>
    </BrowserRouter>
  );
}

export default App;
