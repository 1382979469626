import React, { useEffect, useState } from "react";

import { Modal,Button, Form } from "react-bootstrap";

import metamask_sm from '../images/metamask_sm.png'
import wallet_connect_sm from '../images/wallet_connect_sm.png'
import trust_wallet_sm from '../images/trust_wallet_sm.png'
import wallet_con_1 from '../images/wallet_con_1.png'
import wallet_con_2 from '../images/wallet_con_2.png'
import metamask_big from '../images/metamask_big.png'
import wallet_connect_big from '../images/wallet_connect_big.png'
import trust_wallet_big from '../images/trust_wallet_big.png'




const ClaimModal = (props) =>{
    const[claim , setShowClaim] = useState(true)  
    const [walletcount,setWalletcount] = useState(0);

    return(
        <div className="signinmodal editprofile">
                
            <Modal show={claim} size="lg" className="br-15 thememodal  claimmodal" centered>
             
                <Modal.Body>
                <button onClick={() => props.onDismiss()} className="close-btn">X</button>
                    <div className="row px-3">
                     <p className="title">Claim Rewards</p>
                     </div>
                     <div className="row px-3">
                     <div className="claimlists">
                        <p className="listname">stETH Balance</p>
                        <p>0.00</p>
                     </div>
                     <div className="claimlists">
                        <p className="listname">stETH Earned</p>
                        <p>0.00</p>
                     </div>
                     <div className="claimlists">
                        <p className="listname">Average APR</p>
                        <p>12.6</p>
                     </div>
                     <div className="claimlists">
                        <p className="listname">stETH price</p>
                        <p>$ 1,717.92</p>
                     </div>
                    </div>
                    <div className="claimmodal_btnsec py-2">
                
                    <button className="btn_red mx-2">Cancel</button>
                    <button className="btn_yellow mx-2">Claim</button>
                    
                    </div>
                 
                </Modal.Body>
            </Modal>
        </div>
    )
}


export default ClaimModal