import BigNumber from 'bignumber.js';
import { useWeb3 } from "./useWeb3";
import toast, { Toaster } from 'react-hot-toast';
import { UseERC20, UseTokenInfo, toFixedNumber, UseMasterChef, UsehttpMasterChef, getMasterChefAddress } from './UseContract'



export const getPoolInfoLimit = async (start, end) => {
    try {
        const totalPool = [];
        const length = await getPoolLength();
        if (length > 0) {
            for (var i = start; i <= end; i++) {
                if (i < length) {
                    const poolarr = await getPool(i);
                    totalPool.push(poolarr);
                }
            }
        }
        return totalPool;
    } catch (e) {

    }
}

export const getPool = async (pid) => {
    try {
        // const stakecontract = await UseMasterChef();
        const stakecontract = await UsehttpMasterChef();
        const Pool = await stakecontract.methods.poolInfo(pid).call();
        return Pool;
    } catch (e) {

    }
}

export const getPoolLength = async () => {
    try {
        // const stakecontract = await UseMasterChef();
        const stakecontract = await UsehttpMasterChef();
        const length = await stakecontract.methods.poolLength().call();
        console.log("getPoolLength_getPoolLength", length);
        return length;
    } catch (e) {

    }
}

export const plan = async (pid) => {
    // console.log('PID : ', pid);
    try {
        const stakecontract = await UsehttpMasterChef()
        const planDetails = await stakecontract.methods._plan(pid).call();
        // console.log("planLength",planDetails)
        return planDetails
    } catch (e) {
        console.log("ERR", e)
    }

}

export const getPairPid = async (stakeAddress, rewardAddress) => {
    // console.log('PID : ', stakeAddress,rewardAddress);
    try {
        const stakecontract = await UsehttpMasterChef()
        // console.log("stakecontract>>>>",stakeAddress,rewardAddress);
        const planDetails = await stakecontract.methods.pid(stakeAddress, rewardAddress).call();
        // console.log("planLength",planDetails)
        return planDetails
    } catch (e) {
        console.log("ERR", e)
    }

}


export const getUser = async (pid, account) => {
    try {
        // const stakecontract = await UseMasterChef();
        const stakecontract = await UsehttpMasterChef();
        const User = await stakecontract.methods.userInfo(pid, account).call();
        return User;
    } catch (e) {

    }
}


export const getUSDTprice = async (value, stakeaddress, rewardToken) => {
    console.log("value", value, "stakeaddress", stakeaddress, "rewardToken", rewardToken);
    try {

        let USDTprice = 0;
        if (value > 0) {
            const stakecontract = await UsehttpMasterChef();
            // const stakecontract = await UseMasterChef();
            // USDTprice = await stakecontract.methods.calculatePrice(value).call();
            // USDTprice = await stakecontract.methods.calculatePrice(String(value),stakeaddress).call();
            USDTprice = await stakecontract.methods.Oxgfa(String(value), stakeaddress, rewardToken).call(); // calculatePrice
        }
        console.log("PRICE", USDTprice)
        return USDTprice;
    } catch (e) {

    }




}

export const getUserReward = async (pid, account) => {
    try {
        // const stakecontract = await UseMasterChef();
        const stakecontract = await UsehttpMasterChef();
        const user = await stakecontract.methods.pendingTost(pid, account).call()
        return user;
    } catch (e) {

    }
}

export const getRewardTokenInfo = async (_token) => {
    try {

        const tokeninfo = await UseTokenInfo(_token);
        return tokeninfo;
    } catch (e) {

    }
}

export const getWholeNumber = (value, decimal) => {
    try {
        const balance = new BigNumber(value).times(new BigNumber(10).pow(decimal));
        let finalval = (balance).toNumber();
        finalval = toFixedNumber(finalval);
        return finalval.toString();
    } catch (e) {

    }
}

export const OnStake = async (account, planId, amount, decimal, pid) => {
    console.log("OnStake_OnStake", account, planId, amount, decimal, pid)
    const stakingContract = await UseMasterChef();
    const convertedAmount = getWholeNumber(amount, decimal);

    const data = stakingContract.methods.Oxdabc(pid.toString(), planId.toString(), convertedAmount.toString()).send({ from: account }).on('transactionHash', async (tx) => {
        // console.log("HASH",tx);
        localStorage.setItem("HASH", JSON.stringify(tx));
    })
    // localStorage.setItem("HASH" , JSON.stringify(tx));
    await toast.promise(data, {
        loading: 'Depositing ...',
        success: 'Deposit Successfully',
        error: 'Try Again',
    }, {
        style: {
            minWidth: '300px',
            minHeight: '55px'
        }
    })
    // await window.location.reload()
}

export const UnStake = async (account, pid, amount, decimal, isMax) => {
    const stakingContract = await UseMasterChef();
    const amt = isMax ? toFixedNumber(amount * 10 ** decimal) : toFixedNumber(amount * 10 ** decimal);
    // const convertedAmount = getWholeNumber(amount,decimal);
    const data = stakingContract.methods.Oxozp(pid, amt.toString()).send({ from: account });
    await toast.promise(data, {
        loading: 'Withdrawing ...',
        success: 'Withdraw Successfully',
        error: 'Try Again',
    }, {
        style: {
            minWidth: '300px',
            minHeight: '55px'
        }
    })
    await window.location.reload()
}

// export const Claim = async (account, pid) => {
//     const web3 = await useWeb3();
//     const stakingContract = await UseMasterChef();
//     const datas = stakingContract.methods.Oxmne(pid).encodeABI();

//     var tx = {
//         data: datas,
//         from: account,
//         to: getMasterChefAddress(),
//         value: 0
//     }

//     const data = await web3.eth.sendTransaction(tx);

//     //.send({ from: account});
//     await toast.promise(datas, {
//         loading: 'Claim ...',
//         success: 'claim Rewards Successfully',
//         error: 'Try Again',
//     }, {
//         style: {
//             minWidth: '300px',
//             minHeight: '55px'
//         }
//     })

//     await window.location.reload()
// }



export const Claim = async (account, pid) => {
    const web3 = await useWeb3();
    const stakingContract = await UseMasterChef();
    const datas = stakingContract.methods.Oxmne(pid).send({ from: account});

    // var tx = {
    //     data: datas,
    //     from: account,
    //     to: getMasterChefAddress(),
    //     value: 0
    // }

    // const data = await web3.eth.sendTransaction(tx);

    //.send({ from: account});
    await toast.promise(datas, {
        loading: 'Claim ...',
        success: 'claim Rewards Successfully',
        error: 'Try Again',
    }, {
        style: {
            minWidth: '300px',
            minHeight: '55px'
        }
    })

    await window.location.reload()
}


export const UseRoundValue = (VALUE, DECIMALS) => {
    try {
        return VALUE > 0 ? new BigNumber(VALUE).div(new BigNumber(10).pow(DECIMALS)) : new BigNumber(0);
    } catch (e) {

    }
}

export const convertToDate = (dateString) => {
    try {
        let d = dateString.split("/");
        let dat = new Date(d[2] + '/' + d[1] + '/' + d[0]);
        return dat
    } catch (e) {
        console.log("convertToDate ERR", e)
    }
}

export const FARMS = [
    {
        lpSymbol: 7,
    },
    {
        lpSymbol: 30,
    },
    {
        lpSymbol: 90,
    },
    {
        lpSymbol: 180,
    }
    ,
    {
        lpSymbol: 365,
    }
]

