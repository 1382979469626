import Web3 from "web3"
import WalletConnectProvider from "@walletconnect/web3-provider";
import { CHAINS ,PROJECT_ID} from "../config/env";
import toast, { Toaster } from 'react-hot-toast';
import {sleep} from './UseContract';
import { EthereumProvider } from '@walletconnect/ethereum-provider'


// export const useWeb3 = async() =>{
  
//  const RPC_URL      =  CURRENT_RPC_URL();
//  const CHAIN_ID     = CURRENT_CHAIN_ID();
//  const httpProvider = new Web3.providers.HttpProvider(RPC_URL)

// let web3;

//   if(localStorage.getItem("walletconnect")!=null){
//     const RPC_DATA = {};
//     RPC_DATA[CHAIN_ID] = RPC_URL
//     const ethereum = new WalletConnectProvider({
//         rpc: RPC_DATA,
//         network: 'binance',
//         chainId: CHAIN_ID,
//       }
//     );

//     await ethereum.enable()
//     web3 = new Web3(ethereum);

//   //  return web3;
//   }
//   else if(typeof window.BinanceChain !== 'undefined'){
 
//     web3 = new Web3(window.BinanceChain);
//    // return web3;
//   }
  
//   else if(sessionStorage.getItem("accountInfo")!=null){
//    web3 = new Web3(window.ethereum);
//  //   return web3;
//   }else{
//    web3 = new Web3(httpProvider);
//  //   return web3;
//   }
//   const chainIds = await web3.eth.getChainId();
//   if(parseInt(chainIds) != parseInt(CHAIN_ID)){
//         const data = await SwitchChain();
//         if(data){
//           window.location.reload();
//         }
//     }

//   return web3;
  
// }


export const useWeb3 = async() =>{
  
  const RPC_URL      =  CURRENT_RPC_URL();
  const CHAIN_ID     = CURRENT_CHAIN_ID();
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL)
 
 let web3;
 
  
   if(localStorage.getItem("wcv2")!=null){
     const provider = await EthereumProvider.init({
       projectId: PROJECT_ID, // required
       chains: [CHAIN_ID], // required
       showQrModal: true // requires @walletconnect/modal
     })
     await provider.enable()
     web3 = new Web3(provider);
 
   }
   else if(typeof window.BinanceChain !== 'undefined'){
  
     web3 = new Web3(window.BinanceChain);
    // return web3;
   }
   
   else if(sessionStorage.getItem("accountInfo")!=null){
    web3 = new Web3(window.ethereum);
  //   return web3;
   }else{
    web3 = new Web3(httpProvider);
  //   return web3;
   }
   const chainIds = await web3.eth.getChainId();
   if(parseInt(chainIds) != parseInt(CHAIN_ID)){
         const data = await SwitchChain();
         if(data){
           window.location.reload();
         }
     }
 
   return web3;
   
 }


export const usehttpProvider = async() =>{
  
  const RPC_URL      =  CURRENT_RPC_URL();
  const CHAIN_ID     = CURRENT_CHAIN_ID();
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL)
 
 let web3;
 
   
    web3 = new Web3(httpProvider);
  
   const chainIds = await web3.eth.getChainId();
   if(parseInt(chainIds) != parseInt(CHAIN_ID)){
         const data = await SwitchChain();
         if(data){
           window.location.reload();
         }
     }
 
   return web3;
   
 }



export const UseProvider = async () =>{
  const RPC_URL =  CURRENT_RPC_URL();
  const CHAIN_ID = CURRENT_CHAIN_ID();
  var provider = window.BinanceChain;

  if(localStorage.getItem("walletconnect")!=null){
    const RPC_DATA = {};
    RPC_DATA[CHAIN_ID] = RPC_URL
    const ethereum = new WalletConnectProvider({
        rpc: RPC_DATA,
        network: 'binance',
        chainId: CHAIN_ID,
      
    }
    );
 
     await ethereum.enable()
    
 
     return ethereum;
   }
   else if(typeof window.BinanceChain !== 'undefined'){
  
    return window.BinanceChain;
  }
   else if(sessionStorage.getItem("accountInfo")!=null){
     return window.ethereum;
   }
}



export const CURRENT_RPC_URL = ()=>{
   const index = GetChainIndex()

   return CHAINS[index].RPC_URL;
}

export const GetChainIndex = ()=>{
 
  return localStorage.getItem("CHAIN") ? localStorage.getItem("CHAIN") : "0";
}

export const CURRENT_CHAIN_ID = ()=>{
  const index = GetChainIndex()
  return CHAINS[index].CHAIN_ID;
}

const SwitchChain = async()=>{
  const RPC_URL =  CURRENT_RPC_URL();
  const CHAIN_ID = CURRENT_CHAIN_ID();
     // Check if MetaMask is installed
 // MetaMask injects the global API into window.ethereum
 const hexString = CHAIN_ID.toString(16);
 if (window.ethereum) {
    try {
      // check if the chain to connect to is installed
      
      const data =  window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: "0x"+hexString }], // chainId must be in hexadecimal numbers
      });
      await toast.promise(data, {
        loading: 'Switching Network ...',
        success: 'Network Switched Successfully',
        error: 'Error ! When Switching Network',
    }, {
        style: {
        minWidth: '300px',
        minHeight: '55px'
        }
        
    },
    await sleep(1000));
     return true;
    } catch (error) {
      // This error code indicates that the chain has not been added to MetaMask
      // if it is not, then install it into the user MetaMask
      if (error.code === 4902) {
        try {
          const data =  window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: "0x"+hexString,
                rpcUrl: RPC_URL,
              },
            ],
          });
          await toast.promise(data, {
            loading: 'Switching Network ...',
            success: 'Network Switched Successfully',
            error: 'Error ! When Switching Network',
        }, {
            style: {
            minWidth: '300px',
            minHeight: '55px'
            }
        });
          return true;
        } catch (addError) {
          console.error(addError);
          toast.error(`Error : ${addError}`,
      {
          style: {
          minWidth: '300px',
          minHeight: '55px'
          }
      });
        }
      }
      console.error(error);
    }
  } else {
    // if no window.ethereum then MetaMask is not installed
   
    return false;
    
  } 
}
